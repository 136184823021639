import React from 'react';
import logoImage from 'assets/logo.svg';
import mobileLogo from 'assets/logo-mobile.svg';
import { Link } from 'react-router-dom';

export const HomeNavbar = () => {
  return (
    <nav className="uppercase">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden"></div>
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <Link to="/" className="flex-shrink-0 flex items-center">
              <img
                className="block lg:hidden h-6 w-auto mr-24"
                src={mobileLogo}
                alt="Tickerzone Logo"
              />
              <img
                className="hidden lg:block h-8 w-auto"
                src={logoImage}
                alt="Tickerzone Logo"
              />
            </Link>
          </div>
          <div className="absolute inset-y-0 right-0 flex space-x-2 items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <Link
              to="https://tickerzone.com/auth"
              target="_blank"
              rel="noreferrer"
              className="text-gray-300 uppercase hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-xs  
              font-medium"
            >
              Log in
            </Link>
            <a
              href="https://tickerzone.com/auth"
              target="_blank"
              rel="noreferrer"
              className="border border-flamingo-500 text-gray-300 uppercase hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-xs  
              font-medium"
            >
              Book a Demo
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};
