import { ColorModeProvider } from 'context/ColorContext';
import { AppProvider } from 'providers/app';
import { AppRoutes } from 'routes';

function App() {
  return (
    <ColorModeProvider>
      <AppProvider>
        <AppRoutes />
      </AppProvider>
    </ColorModeProvider>
  );
}

export default App;
