import React from 'react';
import { Link } from 'react-router-dom';

import { useColorModeContext } from 'context/ColorContext';
import { MainLayout } from 'components/layout';

export const NotFound = () => {
  const darkMode = useColorModeContext();
  return (
    <MainLayout className={` ${darkMode[0] ? `dark` : ``}`}>
      <div className="dark:bg-black dark:text-white min-h-screen">
        <div className="my-48 w-full flex flex-col justify-center items-center space-y-4">
          <p className="font-bold text-3xl">404</p>
          <p className="font-bold text-xl">Page doesn't exist</p>
          <Link
            to="/"
            className="bg-flamingo-500 px-4 py-2 text-white rounded-md hover:bg-flamingo-600"
          >
            Go Back Home
          </Link>
        </div>
      </div>
    </MainLayout>
  );
};
