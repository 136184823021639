import PropTypes from 'prop-types';
import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useMutation } from 'react-query';

export const sendMessage = ({ data }) => {
  return axios.post(`/portfolio/notes/add`, data);
};

sendMessage.propTypes = {
  data: PropTypes.shape({
    email: PropTypes.string.isRequired,
    comment: PropTypes.string.isRequired,
  }).isRequired,
};

export const useSendMessage = ({ config } = {}) => {
  return useMutation({
    onSuccess: data => {
      queryClient.invalidateQueries('messages');
    },
    ...config,
    mutationFn: sendMessage,
  });
};
