import React, { useEffect } from 'react';
import { MainLayout } from 'components/layout';
import storage from 'utils/storage';
import AllanAvatar from 'assets/allan.webp';
import PedroAvatar from 'assets/pedro.png';
import GeorgeAvatar from 'assets/george.jpeg';

import NoImageAvatar from 'assets/no-image.png';

export const Landing = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    storage.clearStorage();
  }, []);
  return (
    <>
      <MainLayout>
        <div className="bg-indigo-600">
          <div className="text-2xl md:text-5xl font-bold text-center text-white p-10 mb-3">
            The team that makes it{' '}
            <span className="text-flamingo-500">tick</span>
          </div>
          <div className="mx-8 md:mx-24 mb-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {founders.map((founder, index) => (
                <Card key={index} {...founder} />
              ))}
            </div>
          </div>
          <div className="bg-indigo-500 mx-8 md:mx-24 p-8 text-white mt-18 rounded rounded-t-4xl">
            <div className="text-2xl md:text-5xl font-black mb-8">
              Why <span className="text-flamingo-500">tickerzone</span>?
            </div>
            <div className="">
              At TickerZone, our unwavering mission is to transform investment
              tracking and facilitate intelligent portfolio management. We have
              meticulously crafted a platform that empowers users to make
              informed decisions and take prompt actions, liberating them from
              the need to be constantly tethered to multiple news channels. With
              Tickerzone, you can fearlessly navigate the investment landscape
              with seamless ease.{' '}
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

const founders = [
  {
    id: 1,
    name: 'Allan Ogwang',
    src: AllanAvatar,
    title: 'Co-Founder/CTO',
    bio: 'Berkeley Engineering Ph.D. with data science experience at Google Waymo, Vectra, and Intel. Expert in Financial Engineering from Berkeley Haas.',
  },
  {
    id: 2,
    name: 'Pedro David Espinoza',
    src: PedroAvatar,
    title: 'Co-Founder/CEO',
    bio: 'Berkeley Haas, Stanford, HBS alum. Silicon Valley Leadership Group VP of Business Development. World Economic Forum Vice Curator & Head of Finance',
  },
  {
    id: 3,
    name: 'George Fouche',
    src: GeorgeAvatar,
    title: 'Software Engineer',
    bio: 'Experienced software engineer at GE, Calypso AI, and Sphere. Proficient in software design, including backend, mobile, and frontend. Holds a BS in Computer Engineering from Penn State University.',
  },
  {
    id: 4,
    name: 'Mark Moog',
    src: NoImageAvatar,
    title: 'Principal Data Scientist',
    bio: 'Mark is a seasoned Data Scientist with 5 years of dedicated experience in the Cybersecurity industry. Additionally, he holds a Ph.D. in Physics and excels as a full-stack software developer.',
  },
  {
    id: 5,
    name: 'Jonathan Mwebaze',
    src: NoImageAvatar,
    title: 'Product Designer',
    bio: 'Talented Product Designer. With a keen eye for detail and a passion for user-centered design, Jonathan plays a crucial role in crafting the user experience of our products.',
  },
  {
    id: 6,
    name: 'Raymond Ntwali',
    src: NoImageAvatar,
    title: 'Frontend Developer',
    bio: 'Proficient frontend coder with a knack for creating seamless and responsive user interfaces. With his contributions, our applications come to life on the web, providing users with a smooth and enjoyable experience.',
  },
];

const Card = ({ src, name, title, bio }) => (
  <div className="bg-indigo-500 p-8 rounded-md shadow hover:bg-gray-900">
    <img src={src} alt={name} className="rounded-lg" />
    <div className="flex flex-col mt-3">
      <div className="text-xl text-white">{name}</div>
      <div className="text-xl text-gray-500">{title}</div>
      <div className="text-sm text-gray-500 mt-4">{bio}</div>
    </div>
  </div>
);
