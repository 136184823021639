// ColorModeContext.js
import { useDarkMode } from 'hooks/useDarkMode';
import React, { createContext, useContext } from 'react';

const ColorModeContext = createContext();

export const useColorModeContext = () => useContext(ColorModeContext);

export const ColorModeProvider = ({ children }) => {
  const colorModeHook = useDarkMode();

  return (
    <ColorModeContext.Provider value={colorModeHook}>
      {children}
    </ColorModeContext.Provider>
  );
};
