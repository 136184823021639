import { MainLayout } from 'components/layout';

import React from 'react';
import { Link } from 'react-router-dom';

export const Terms = () => {
  return (
    <MainLayout>
      <div className="mx-8">
        <h1 className="font-bold text-2xl my-12">Welcome Jonathan</h1>
        <p className="my-2">
          Before we get started, can we send you email sand do you agree with
          tickerzone’s terms?
        </p>
        <form className="space-y-6 my-4">
          <label className="flex space-x-4">
            <input
              type="checkbox"
              className="h-6 w-6 mr-4 rounded-md bg-green-400"
            />
            I have read and accept the terms of service and privacy policy
          </label>
          <label className="flex space-x-4">
            <input
              type="checkbox"
              className="h-6 w-6 mr-4 rounded-md text-white accent-green-400 checked"
            />
            I want to receive news about Tickerzone by email
          </label>
          <div className="py-6 w-full">
            <Link
              to="/auth/create-portfolio"
              className="w-full bg-flamingo-500 hover:bg-flamingo-600 rounded-md p-3 text-white px-12"
            >
              <span>Accept</span>
            </Link>
          </div>
        </form>
      </div>
    </MainLayout>
  );
};
