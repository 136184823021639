import clsx from 'clsx';

import { FieldWrapper } from './FieldWrapper';
import { useColorModeContext } from 'context/ColorContext';

export const InputField = props => {
  const {
    type = 'text',
    label,
    className,
    maxLength,
    caption,

    registration,
    error,
  } = props;

  const darkMode = useColorModeContext();
  // limit image dimensions to 50x50

  const handleInput = event => {
    const { value } = event.target;
    if (type === 'number' && value.startsWith('-')) {
      // Prevent negative values for input of type "number"
      event.target.value = value.replace(/-/g, ''); // Remove all '-' characters
    }
  };

  const handleChange = event => {
    const { value } = event.target;
    if (type === 'number' && value.startsWith('-')) {
      // Prevent negative values for input of type "number"
      event.target.value = value.replace(/-/g, ''); // Remove all '-' characters
    }
  };

  return (
    <FieldWrapper label={label} error={error}>
      <div className="text-xs my-1 text-gray-400">{caption}</div>
      <input
        type={type}
        maxLength={maxLength}
        className={clsx(
          `border border-solid ${error && `border-red-500`} ${
            darkMode[0] ? `bg-dim-100` : `bg-gray-100`
          } border-gray-100 rounded-md w-full outline-none 
          focus:outline-none px-8 py-3 dark:bg-gray-900`,
          className
        )}
        onInput={handleInput} // Handle input to block negative values
        onChange={handleChange}
        {...registration}
      />
    </FieldWrapper>
  );
};
