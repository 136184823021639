import clsx from 'clsx';
import { useColorModeContext } from 'context/ColorContext';

export const FieldWrapper = props => {
  const { label, className, error, children } = props;

  const darkMode = useColorModeContext();
  return (
    <div>
      <label
        className={`${darkMode[0] ? `text-gray-200` : `text-gray-700`}${clsx(
          'block text-sm font-medium ',
          className
        )}`}
      >
        {label}
        <div className="mt-1">{children}</div>
      </label>
      {error?.message && (
        <div
          role="alert"
          aria-label={error.message}
          className="text-xs ml-4 font-semibold text-red-500"
        >
          {error.message}
        </div>
      )}
    </div>
  );
};
