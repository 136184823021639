import { FullScreenLoader } from 'components/elements';
import { Notifications } from 'components/Notifications/Notifications';
import { queryClient } from 'lib/react-query';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import storage from 'utils/storage';
import logo from 'assets/logo-dark.svg';
import darklogo from 'assets/logo.svg';
import { useColorModeContext } from 'context/ColorContext';
// import { useEffect } from 'react';
// import { refreshToken } from 'features/auth';

const ErrorFallback = () => {
  const handleRefresh = () => {
    storage.clearStorage();
    window.location.assign('/');
  };

  const darkMode = useColorModeContext();

  return (
    <div className={darkMode[0] ? `dark` : ``}>
      <div className="dark:bg-black dark:text-white min-h-screen">
        <div className="mx-8 md:mx-64 pt-64 flex flex-col items-start space-y-4">
          <img
            src={darkMode[0] ? darklogo : logo}
            alt="tickerzone logo"
            className="h-4"
          />
          <div className="text-flamingo-500">Something went wrong</div>
          <div className="text-gray-800 dark:text-white">
            Please try the recommended action below
          </div>
          <div className="flex flex-col md:flex-row space-y-2 md:space-x-2">
            <button
              onClick={handleRefresh}
              className="bg-flamingo-500 p-2 px-4 w-64 text-white"
            >
              Refresh Application
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const AppProvider = ({ children }) => {
  return (
    <React.Suspense fallback={<FullScreenLoader />}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
            <Notifications />
            <Router>{children}</Router>
          </QueryClientProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
