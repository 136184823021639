import React from 'react';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { useColorModeContext } from 'context/ColorContext';

export const MainLayout = ({ children, headerHeight, headerContent }) => {
  const darkMode = useColorModeContext();

  return (
    <div className={`${darkMode[0] ? `dark` : ``}`}>
      <div className="relative">
        <div className="z-1 absolute top-0 w-full">
          <Header height={headerHeight} children={headerContent} />
          <div className="dark:bg-dim-300 dark:text-white">{children}</div>
          <Footer />
        </div>
      </div>
    </div>
  );
};
