import { isValidJSON } from './isValidJSON';
import CryptoJS from 'crypto-js';

const storagePrefix = 'msw-db';
const encryptionKey = 'oh87h87h87wh4fg34gfiwg7wg346fgo84h37o';

const storage = {
  encrypt: data => {
    const encryptedData = CryptoJS.AES.encrypt(data, encryptionKey).toString();
    return encryptedData;
  },

  decrypt: encryptedData => {
    try {
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, encryptionKey);
      const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
      return decryptedData;
    } catch (error) {
      console.error('Decryption error:', error);
      return null;
    }
  },
  setToken: token => {
    const encryptedToken = storage.encrypt(JSON.stringify(token));
    window.sessionStorage.setItem(`${storagePrefix}token`, encryptedToken);
  },

  getToken: () => {
    const encryptedToken = window.sessionStorage.getItem(
      `${storagePrefix}token`
    );
    if (!encryptedToken) return null;
    const decryptedToken = storage.decrypt(encryptedToken);
    return isValidJSON(decryptedToken) ? JSON.parse(decryptedToken) : null;
  },
  setRefreshToken: token => {
    const encryptedToken = storage.encrypt(JSON.stringify(token));
    window.sessionStorage.setItem(
      `${storagePrefix}refreshtoken`,
      encryptedToken
    );
  },

  getRefreshToken: () => {
    const encryptedToken = window.sessionStorage.getItem(
      `${storagePrefix}refreshtoken`
    );
    if (!encryptedToken) return null;
    const decryptedToken = storage.decrypt(encryptedToken);
    return isValidJSON(decryptedToken) ? JSON.parse(decryptedToken) : null;
  },
  setErrorMessage: error => {
    sessionStorage.setItem('errorMessage', JSON.stringify(error));
  },
  setErrorResponse: error => {
    sessionStorage.setItem('errorResponse', JSON.stringify(error));
  },
  setActivePortfolio: portfolio_id => {
    const encryptedPortfolio = storage.encrypt(JSON.stringify(portfolio_id));
    window.sessionStorage.setItem(
      `${storagePrefix}selectedPortfolioId`,
      encryptedPortfolio
    );
  },
  getActivePortfolio: () => {
    const encryptedPortfolio = window.sessionStorage.getItem(
      `${storagePrefix}selectedPortfolioId`
    );
    if (!encryptedPortfolio) return null;
    const decryptedPortfolio = storage.decrypt(encryptedPortfolio);
    return isValidJSON(decryptedPortfolio)
      ? JSON.parse(decryptedPortfolio)
      : null;
  },
  setActivePortfolioName: portfolio => {
    sessionStorage.setItem(`selectedPortfolio`, JSON.stringify(portfolio));
  },
  getActivePortfolioName: () => {
    return JSON.parse(sessionStorage.getItem('selectedPortfolioName'));
  },
  getErrorMessage: () => {
    return JSON.parse(sessionStorage.getItem('errorMessage'));
  },
  getErrorResponse: () => {
    return JSON.parse(sessionStorage.getItem('errorResponse'));
  },
  clearError: () => {
    sessionStorage.removeItem(`errorMessage`);
    sessionStorage.removeItem(`errorResponse`);
  },
  clearToken: () => {
    sessionStorage.removeItem(`${storagePrefix}token`);
  },
  clearStorage: () => {
    sessionStorage.clear();
  },
};

export default storage;
