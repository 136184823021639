import React, { useEffect } from 'react';
import * as z from 'zod';
import { Form, InputField, TextAreaField } from 'components/form';
import { Check } from 'react-feather';
import { Button, Spinner } from 'components/elements';
import { useSendMessage } from '../api/sendMessage';
import { useNavigate } from 'react-router-dom';

const schema = z.object({
  email: z.string(),
  comment: z.string().min(1, 'Required'),
});

export const ContactUs = () => {
  const sendMessageMutation = useSendMessage();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  if (sendMessageMutation.isLoading) {
    return (
      <div
        id="loading-screen"
        className="w-full h-full fixed block top-0 left-0 bg-black opacity-85 z-50"
      >
        <span className="opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
          <Spinner size="md" />
        </span>
      </div>
    );
  }

  return (
    <>
      <Form
        id="send-message"
        onSubmit={async values => {
          await sendMessageMutation.mutateAsync({ data: values });
          navigate('./contact-us/thank-you');
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <TextAreaField
              label="How can we help you?"
              error={formState.errors['comment']}
              registration={register('comment')}
            />
            <InputField
              type="email"
              label="What's your email address?"
              error={formState.errors['email']}
              registration={register('email')}
              // caption="We promise not to share your email with anyone."
            />
            <div>
              <Button
                type="submit"
                endIcon={<Check className="h-4 w-4" />}
                className="w-64 flex flex-row space-x-4 items-center bg-flamingo-500 hover:bg-flamingo-600 rounded-md p-3 text-white px-12"
              >
                <span>Send Message</span>
              </Button>
            </div>
          </>
        )}
      </Form>
    </>
  );
};
