import { useColorModeContext } from 'context/ColorContext';
import React from 'react';
import { MutatingDots } from 'react-loader-spinner';

export const FullScreenLoader = () => {
  const darkMode = useColorModeContext();

  return (
    <div className={darkMode[0] ? `dark` : ``}>
      <div
        id="loading-screen"
        className="w-full h-full fixed top-0 left-0 bg-gray-100 dark:bg-dim-300 z-50"
        style={{
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <MutatingDots
          height={100}
          width={100}
          color="#FE5F00"
          secondaryColor="#FE5F00"
          radius={12.5}
          ariaLabel="mutating-dots-loading"
          visible={true}
        />
      </div>
    </div>
  );
};
