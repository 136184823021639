import React from 'react';
import { Instagram, Linkedin, MapPin, Twitter } from 'react-feather';
// import footerImg from 'assets/footer-logo.svg';
import { Link } from 'react-router-dom';
import { ContactUs } from './ContactUs';

export const Footer = () => {
  return (
    <footer className="bg-indigo-600">
      <div className="px-4 sm:px-6 lg:px-8 py-2">
        <div className="mx-8 md:mx-24 py-4">
          <hr className="border border-gray-800" />
        </div>
        <div className="mx-8 md:mx-24 grid grid-cols-1 md:grid-cols-2 gap-24">
          <div className="">
            <h3 className="text-white text-lg font-semibold mb-4">
              Contact Us
            </h3>
            <ContactUs />
          </div>
          <div className="">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <h3 className="text-white text-lg font-semibold mb-4">Legal</h3>
                <ul className="space-y-2">
                  <li>
                    <Link
                      to="/terms-of-use"
                      className="text-gray-300 hover:text-white"
                    >
                      Terms of Use Agreement
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/referral"
                      className="text-gray-300 hover:text-white"
                    >
                      Referral Agreement
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/license"
                      className="text-gray-300 hover:text-white"
                    >
                      License Agreement
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/privacy-policy"
                      className="text-gray-300 hover:text-white"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>

              {/* Company */}
              <div>
                <h3 className="text-white text-lg font-semibold mb-4">
                  Company
                </h3>
                <ul className="space-y-2">
                  <li>
                    <Link
                      to="/about"
                      className="text-gray-300 hover:text-white"
                    >
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact-us"
                      className="text-gray-300 hover:text-white"
                    >
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" className="text-gray-300 hover:text-white">
                      Blog
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="text-white mt-8">
              <h3 className="text-white text-lg font-semibold mb-4">Address</h3>
              <div className="mb-3 flex flex-row space-x-2 items-center">
                <MapPin className="h-4 w-4" />
                <span>201 W Main St, Suite 100, Durham, NC 27701</span>
              </div>
              <div className="mb-3 flex flex-row space-x-2 items-center">
                <MapPin className="h-4 w-4" />
                <span>101 Bedford Ave, Suite D402, Brooklyn, NY</span>
              </div>
            </div>

            <div>
              <ul className="flex flex-row items-center space-x-4">
                <li>
                  <a
                    href="https://twitter.com/tickerzone"
                    target="_blank"
                    rel="noreferrer"
                    className="text-gray-300 hover:text-white"
                  >
                    <Twitter className="h-24" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://instagram.com/tickerzone"
                    target="_blank"
                    rel="noreferrer"
                    className="text-gray-300 hover:text-white"
                  >
                    <Instagram className="h-24" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://linkedin.com/company/tickerzone"
                    target="_blank"
                    rel="noreferrer"
                    className="text-gray-300 hover:text-white"
                  >
                    <Linkedin className="h-24" />
                  </a>
                </li>
              </ul>
              {/* <img className="" src={footerImg} alt="Tickerzone Logo" /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="pb-8 text-white text-center text-sm">
        Tickerzone Inc. 2023. All rights reserved.
      </div>
    </footer>
  );
};
